import request from '@/utils/request'

export function login(username, password, uuid, captcha, mobile, code) {
  const data = {
    username,
    password,
    uuid,
    captcha,
    mobile,
    code
  }
  return request({
    url: '/sys/login',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/user/info',
    method: 'get',
    params: { token }
  })
}

export function sendCode(params) {
  return request({
    url: '/sendCode',
    method: 'get',
    params:params
  })
}

export function logout() {
  return request({
    url: '/sys/logout',
    method: 'post'
  })
}
