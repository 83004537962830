import request from '@/utils/request'

export function selCatDist(params) {
  return request({
    url: '/product/product/selectCategoryDistribution',
    method: 'get',
    params
  })
}

export function queryProduct() {
  return request({
    url: '/product/product/queryProduct',
    method: 'get'
  })
}

export function selDevOverview() {
  return request({
    url: '/device/deviceuser/selectDeviceOverview',
    method: 'get'
  })
}

export function selHisOverview() {
  return request({
    url: '/searchHistory/searchhistory/selectHistoryOverview',
    method: 'get'
  })
}

export function selectBrandInfo() {
  return request({
    url: '/sys/user/selectBrandInfo',
    method: 'get'
  })
}

export function selectFactoryList() {
  return request({
    url: '/factory/factory/selectFactoryList',
    method: 'get'
  })
}

export function selectFactoryModular(params) {
  return request({
    url: '/factory/factory/selectFactoryModular',
    method: 'get',
    params
  })
}

export function selectInputStatistics(params) {
  return request({
    url: '/factory/factory/selectInputStatistics',
    method: 'get',
    params
  })
}

export function findSearchCountTrend() {
  return request({
    url: '/searchHistory/searchhistory/findSearchCountTrend',
    method: 'get'
  })
}

export function selectNavigationList() {
  return request({
    url: '/sys/navigation/selectNavigationList',
    method: 'get'
  })
}

export function selectDeviceList(params) {
  return request({
    url: '/device/device/selectDeviceList',
    method: 'get',
    params
  })
}

export function checkDeviceState(params) {
  return request({
    url: '/device/device/checkDeviceState',
    method: 'get',
    params
  })
}

export function confirmSelectDevice(params) {
  return request({
    url: '/device/device/confirmSelectDevice',
    method: 'get',
    params
  })
}

export function breakConnectDevice(params) {
  return request({
    url: '/device/device/breakConnectDevice',
    method: 'get',
    params
  })
}
