<template>
  <div class="menu-wrapper">
    <a href="#/homePage" class="router-link-exact-active router-link-active">
      <li style="padding: 20px 20px 20px 10px; color: rgb(255, 255, 255);">
        <img src="../../../../assets/home_logo.png" style="width:100%;"/>
        <!-- <svg-icon v-show="sidebarStatus" icon-class="home_logo" style="width:100%;height:37px" /> -->
      </li>
    </a>
    <template v-for="item in routes" v-if="!item.hidden&&item.children">
      <router-link v-if="hasOneShowingChildren(item.children) && !item.children[0].children&&!item.alwaysShow" :to="item.path+'/'+item.children[0].path" :key="item.children[0].name">
        <el-menu-item :index="item.path+'/'+item.children[0].path" :class="{'submenu-title-noDropdown':!isNest}">
          <svg-icon v-if="item.children[0].meta&&item.children[0].meta.icon" :icon-class="item.children[0].meta.icon"></svg-icon>
          <span v-if="item.children[0].meta&&item.children[0].meta.title" slot="title">{{item.children[0].meta.title}}</span>
        </el-menu-item>
      </router-link>

      <el-submenu v-else :index="item.name||item.path" :key="item.name">
        <template slot="title">
          <!-- <i v-if="item.meta&&item.meta.icon" :class="item.meta.icon"></i> -->
          <svg-icon v-if="item.meta&&item.meta.icon" :icon-class="item.meta.icon"></svg-icon>
          <span v-if="item.meta&&item.meta.title" slot="title">{{item.meta.title}}</span>
        </template>

        <!-- <template v-for="child in item.children" v-if="!child.hidden"> -->
        <template v-for="child in item.children" v-if="isShow(child.meta.title)">
          <sidebar-item :is-nest="true" class="nest-menu" v-if="child.children&&child.children.length>0" :routes="[child]" :key="child.path"></sidebar-item>

          <router-link v-else :to="item.path+'/'+child.path" :key="child.name">
            <el-menu-item :index="item.path+'/'+child.path">
              <svg-icon v-if="child.meta&&child.meta.icon" :icon-class="child.meta.icon"></svg-icon>
              <span v-if="child.meta&&child.meta.title" slot="title">{{child.meta.title}}</span>
            </el-menu-item>
          </router-link>
        </template>
      </el-submenu>

    </template>
  </div>
</template>

<script>
//  Cookies.set('sidebarStatus', 1)
//     } else {
//       Cookies.set('sidebarStatus', 0)
import Cookies from 'js-cookie'
export default {
  name: "SidebarItem",
  props: {
    routes: {
      type: Array
    },
    isNest: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    sidebarStatus() {
      return this.$store.state.app.sidebar.opened
    }
  },
  data() {
    return {
    }
  },
  created() {
    console.log(this.routes)
  },
  methods: {
    hasOneShowingChildren(children) {
      const showingChildren = children.filter(item => {
        return item.meta.title === "首 页";
      });
      if (showingChildren.length === 1) {
        return true;
      }
      return false;
    },
    isShow(title) {
      switch (title) {
        default:
          return true;
      }
    }
  }
};
</script>
<!--需要隐藏的路由菜单

-->
<style  lang="scss">
.menu-wrapper{
  img {   
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;    
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }
}

</style>
